import React, { Component } from "react";

class Error404 extends Component {
  state = {};
  render() {
    return (
      <>
        <h1>We are experiencing Some Technical Issues, We Will be Back to Soon!</h1>
      </>
    );
  }
}

export default Error404;
